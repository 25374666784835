.nav {
    overflow: hidden;
    box-shadow: 0 0 3.6px 0 rgb(0 0 0 / 13%), 0 0 0.9px 0 rgb(0 0 0 / 11%);
    z-index: 10;
}

.navicon {
    height: 70%;
}

.MuiBottomNavigationAction-root img {
    margin: 10px;
}