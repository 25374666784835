.welcome {
    color: #fff;
    padding: 40px 24px 32px 24px;
    flex-shrink: 0;
}

.welcome-title {
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.8);
}

.slider-wrapper {
    position: relative;
}

.slider-bg {
    background-color: #fff;
    border-radius: 32px 0 0 0;
    flex-shrink: 0;
    position: absolute;
    z-index: -1;
    top: 10%;
    height: 100%;
    width: 100%;
}

.slides {
    display: grid;
    grid-gap: 15px;
    /*grid-template-columns: repeat(5, calc(70% - 0px));*/
    grid-template-columns: calc(320px + 24px) repeat(4, 320px);
    grid-template-rows: minmax(150px, 1fr);
    margin-bottom: 20px;
}

.slides > div:first-child {
    margin-left: 24px;
}

.slides > div {
    /*box-shadow: 0 0 3.6px 0 rgb(0 0 0 / 13%), 0 0 0.9px 0 rgb(0 0 0 / 11%);*/
    box-shadow: 1px 3px 5px 0px #8c8c8c;
    margin-bottom: 5px;
}

.slides-txt-div {
    position: absolute;
    bottom: 13px;
    left: 13px;
    color: white;
}

.slides-txt {
    margin: 0 13px 0 0;
}

.slides-txt-title {
    font-weight: bold;
    margin: 0 13px 0 2px;
}

.slides-btn-txt {
    color: #0d8666 !important;
    font-size: 15px;
}

.allow-scroll {
    -ms-overflow-style: none;
    scrollbar-width: none;
    overscroll-behavior: contain;
    -webkit-overflow-scrolling: touch;
}

.allow-scroll-vertical {
    overflow-y: auto;
}

.allow-scroll-horizontal {
    overflow-x: auto;
}

.allow-scroll::-webkit-scrollbar {
    display: none;
}

.white {
    color: white;
}

.black {
    color: black;
}

.slides2-div {
    width: auto;
    padding: 0 24px 0 24px;
}

.slides2-div > div > div {
    box-shadow: 1px 3px 5px 0px #8c8c8c;
}

.slider2-txt {
    margin: 0;
    text-align: center;
}