.white-shadow {
    box-shadow: 0 0 0 max(100vh, 100vw) rgba(0, 0, 0, .6);
    position: absolute;
    width: 70vw;
    height: 70vw;
    margin: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
}

.qrcode-scan {
    position: absolute;
    width: 75vw;
    height: 75vw;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    z-index: 10;
}

.qrcode-scan-txt {
    color: white;
    position: absolute;
    text-align: center;
    width: 100%;
    top: 77%;
    left: 50%;
    transform: translate(-50%, -77%);
    -webkit-transform: translate(-50%, -77%);
}

.video {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

*::-webkit-media-controls-panel {
    display: none !important;
    -webkit-appearance: none;

}

*::--webkit-media-controls-play-button {
    display: none !important;
    -webkit-appearance: none;
}

*::-webkit-media-controls-start-playback-button {
    display: none !important;
    -webkit-appearance: none;
}