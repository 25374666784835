.cross {
    position: absolute !important;
    top: 1%;
    left: 98%;
    transform: translate(-98%, -1%);
    -webkit-transform: translate(-98%, -1%);
}

.inroom-icon {
    margin: 2% auto 0 auto;
    height: 100px;
    width: 100px;
}

.inroom-venue {
    color: gold;
    text-shadow: 1px 1px 1px #262626;
    text-align: center;
    margin: 0;
}

.inroom-time {
    color: white;
    text-shadow: 1px 1px 1px #595959;
    text-align: center;
    margin: 0;
}

.vaccine-record-container {
    width: 70%;
    height: 100%;
    margin: 5% auto 0 auto;
    background-color: #cc0000;
    border-radius: 8px;
    flex-grow: 1;
}

.have-vaccine-record-container {
    background-color: midnightblue;
}

.vaccine-record {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

.no-vaccine-record {
    color: white;
    margin: 4% auto;
}

.no-vaccine-record-icon {
    margin: auto 0;
    color: white;
    flex-grow: 1;
    font-size: 700% !important;
}

.have-vaccine-record-qrcode {
    margin: auto 0;
    width: 100% !important;
    background-color: white;
    flex-grow: 1;
    font-size: 700% !important;
    border-radius: 0 0 8px 8px;
    position: relative;
}

.have-vaccine-record-qrcode > canvas {
    width: 80% !important;
    height: 80% !important;

    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
}

.timer-div {
    display: flex;
    flex-direction: row;
    margin: 16% auto 2% auto;
    background-color: white;
    border-radius: 4px;
    width: 60%;
    justify-content: center;
}

.timer-div > h3:first-child {
    margin: 10px 2% 10px auto;
}

.timer-div > h3:last-child {
    margin: 10px auto 10px 2%;
}

.timer-div > h3 {
    margin: 10px 2% 10px 2%;
    font-size: x-large;
    font-family: 'Aldrich', sans-serif;
    font-weight: lighter;
}

.timer-colon {
    color: orangered;

    -webkit-animation: timer-colon 0.5s linear infinite;
    -moz-animation: timer-colon 0.5s linear infinite;
    animation: timer-colon 0.5s linear infinite;
}

@keyframes timer-colon {
    0% {
        color: orangered;
    }
    100% {
        color: transparent;
    }
}

@-webkit-keyframes timer-colon {
    0% {
        color: orangered;
    }
    100% {
        color: transparent;
    }
}

.leave-btn {
    display: block !important;
    color: black !important;
    font-weight: bold !important;
    font-size: medium !important;
    background-color: rgb(255, 222, 0) !important;
    border-radius: 50px !important;
    box-shadow: 0 2px 3px #595959;
    margin: 0 auto !important;
    width: 70%;
}

.auto-leave-tick-container {
    border: 2px solid white;
    border-radius: 5px;
    height: 3.5vh;
    width: 3.5vh;
}

.auto-leave-tick {
    color: white;
}

.auto-leave-txt {
    color: white;
    text-shadow: 1px 1px 1px #595959;
}

.auto-leave-btn {
    color: yellow !important;
    text-decoration: underline !important;
    margin: 0 0 0 auto !important;
}

.leave-btn-2 {
    display: flex !important;
    flex-direction: row;
    justify-content: center;
    color: black !important;
    font-weight: bold !important;
    font-size: medium !important;
    background-color: rgb(255, 222, 0) !important;
    border-radius: 50px !important;
    margin: 20% auto 2% auto !important;
    width: 65%;
}

.timer-div-2 {
    margin: 1% 3%;
    border-radius: 100px;
}

.timer-div-2 > h3, .timer-div-2 > h3:first-child, .timer-div-2 > h3:last-child {
    margin: 3px 2% 1px 2%;
    font-size: large;
}

.leave-btn-already {
    display: block !important;
    color: white !important;
    border: 1px solid white !important;
    border-radius: 50px !important;
    font-weight: bold !important;
    font-size: medium !important;
    margin: 0 auto 0 auto !important;
    width: 65%;
    text-shadow: 0 2px 1px #595959;
}

.auto-leave-txt-2 {
    display: block;
    color: white;
    text-shadow: 1px 1px 1px #595959;
    text-align: center;
    margin: 3% 0 4% 0;
}