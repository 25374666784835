body {
    margin: 0;
    font-family: Rubik, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    overflow: hidden;
    background-color: #12b188;
    width: 100%;
    height: 100%;
    position: relative;

    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
}

.txt {
    -webkit-user-select: none;
}

html, body, .page {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.page-disable-scroll {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.innerPage {
    background-color: white;
}