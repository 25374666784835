.venuetimer-container {
    background-color: rgb(255, 222, 0);
    border-radius: 50%;
    position: absolute;
    text-align: center;
    height: 100px;
    width: 100px;
    top: 77%;
    left: 50%;
    transform: translate(-50%, -77%);
    -webkit-transform: translate(-50%, -77%);
    box-shadow: 0px 2px 3px #595959;
}