.header {
    color: #ffffff;
    background-color: #12b188;
    text-align: center;
    line-height: 48px;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.8);
    flex-shrink: 0;
    position: fixed;
    width: 100%;
    z-index: 10;
}

.backBtn {
    height: 20px;
    top: 14px;
    left: 16px;
    position: absolute !important;
}

.headerMargin {
    margin-top: 48px;
}